<template>
  <div id="login-page">
    <transition name="scale" @after-enter="afterEnterEvent">
      <div class="form" v-if="isFormLoaded">
        <!-- <router-link to="/">
        <div class="logo">
          <img src="@/assets/image/logo.png" alt="LOGO" draggable="false" />
        </div>
        </router-link>-->
        <div class="header">
          <span>{{$t('auth.login')}}</span>
        </div>
        <div class="input-group">
          <input
            v-model="form.usr_email"
            :placeholder="placeholderTranslations.email"
            :title="placeholderTranslations.email"
            type="text"
            @keypress.enter="loginForm"
          />
        </div>
        <div class="input-group">
          <input
            v-model="form.password"
            :placeholder="placeholderTranslations.password"
            :title="placeholderTranslations.password"
            type="password"
            @keypress.enter="loginForm"
          />
        </div>
        <button class="acc" :disabled="isApiLoading" @click="loginForm">
          <span :class="{'progress': isApiLoading}">{{$t('auth.login')}}</span>
        </button>
        <router-link to="/" class="btn">
          <span>{{$t('app.cancelAlt')}}</span>
        </router-link>
        <router-link :to="{name: 'RegisterPage'}" class="auth-link">
          <span>{{$t('auth.dontHaveAccount')}}.</span>
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { mapActions } from 'vuex';
export default {
  mixins: [formMixin],
  data () {
    return {
      isFormLoaded: false,
      form: {},
      isApiLoading: false,
    };
  },
  mounted () {
    localStorage.removeItem('sid');
    localStorage.removeItem('user');
    this.$nextTick(() => (this.isFormLoaded = true));
  },
  beforeDestroy () {
    this.isFormLoaded = false;
  },
  methods: {
    ...mapActions({
      postLogin: 'postLogin',
    }),
    afterEnterEvent (evt) {
      this.$emit('after-enter', evt);
      this.formManageInputPlaceholder();
    },
    async loginForm () {
      this.isApiLoading = true;
      try {
        const res = await this.postLogin(this.form);
        if (res.data.sid) {
          await this.setupUserDataToStorage(res.data);

          // Goto first page after login
          this.$emit('logged-in', res);
        } else {
          console.log('No SID', res.data);
          // this.showErrMessage(res.data && res.data.message)
        }
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      } finally {
        this.isApiLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 50px;
  flex: 1;

  .form {
    @include forms;

    a {
      text-decoration: none;
    }
  }

  .scale-enter-active,
  .scale-leave-active {
    transition: transform 0.2s ease;
  }

  .scale-enter,
  .scale-leave-to {
    transform: scale(0.8);
  }
}
</style>
