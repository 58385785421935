<template>
  <div id="auth">
    <app-full-nav v-if="!isMobileView"></app-full-nav>
    <app-mob-nav v-if="isMobileView"></app-mob-nav>
    <router-view @logged-in="loggedInFunc"></router-view>
  </div>
</template>

<script>
import AppFullNav from '@/components/App/Nav/AppFullNav.vue';
import AppMobNav from '@/components/App/Nav/AppMobNav.vue';
import { mapActions } from 'vuex';
export default {
  name: 'AuthPage',
  components: {
    AppFullNav,
    AppMobNav,
  },
  mounted () {
    this.resetStorageData();
  },
  methods: {
    ...mapActions({
      setUserData: 'setUserData',
      setClientData: 'setClientData',
    }),
    async loggedInFunc (res) {
      await this.updateAppVersionStore(res.data.appFrontVersion);
      this.$router.push({
        name: 'ClientTableListAll',
      });
    },
    resetStorageData () {
      this.setUserData({});
      this.setClientData({});
    },
  },
};
</script>

<style lang="scss" scoped>
#auth {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $intro-back;

  > #login-page,
  > #register-page {
    flex: 1;
  }
}
</style>
